import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Innovaatioseteli, ohjelmistoille mahdollisuus"
        description="Jos tarvitset uutta kumppania digitaalisen liiketoiminnan osalta niin ota yhteyttä!"
        pageImage={metaImg}
        pageUrl={links.innovaatioSeteli}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <span className="block">
              <h1>Innovaatioseteli</h1>
            </span>
            <span className="block text-indigo-400">palvelut</span>
          </HeroTitle>
          <HeroLead>
            Tuotamme projekteja myös innovaatioseteleiden avulla!
          </HeroLead>
        </Hero>
      </section>

      <section>
        <DotSection dotPosition="top-right">
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div>
              <Title tag="p">Innovaatioseteli</Title>
              <Text>
                Tuotamme projekteja myös innovaatioseteleiden avulla.
                Innovaatioseteli on tarkoitettu pienille ja keskisuurille
                yrityksille, joilla on mahdollisuus päästä kansainvälisille
                markkinoille uuden tuotteen tai palvelun kanssa.
                Innovaatioseteleiden avulla yritykset voivat käyttää
                ulkopuolisen yrityksen osaamista sekä ammattitaitoa ja sen
                avulla yrittävät saada liiketoimintaa kasvamaan ja samalla
                kannustamaan yrityksiä innovaatiotoiminnan piiriin.
              </Text>
              <Text>
                Innovaatioseteleiden hakijat ovat aina yrityksiä, jotka ovat
                aikeissa ostaa tuotteita/palveluita palveluntuottajalta. Jos
                tarvitset uutta kumppania uuteen tuotteeseen tai palveluun
                digitaalisen liiketoiminnan osalta, esimerkiksi sovellusten,
                applikaatioiden tai ohjelmistojen osalta niin ota yhteyttä ja
                tehdään vaikka hakemus yhdessä, jotta saamme projekteille
                mahdollisuuden onnistua!
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/1-right-trans-color.svg"
                alt="Profiilikuva hehkulampun päällä"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="what-is" className="bg-gray-800">
        <Container>
          <Title tag="h2" textColor="text-white">
            Mikä innovaatioseteli oikein on?
          </Title>
          <Text textColor="text-white">
            Innovaatiosetelin avulla pystyt ostamaan tietoa ja osaamista
            yritykseesi niillä osa-alueilla, joihin tarvitset ulkopuolisen
            yrityksen apua. Setelin arvo on 6200 € (5000 € + alv.). Ostettu
            palvelu voi olla myös suurempi mitä setelin arvo on, jolloin yritys
            maksaa erotuksen palveluntuottajalle. Setelin avulla pystymme
            esimerkiksi tuottamaan ohjelmistokehitys- ja webkehitys ratkaisuita
            halvemmalla hinnalla mitä muuten yrityksesi joutuisi niistä
            kustantamaan.
          </Text>
          <Text textColor="text-white">
            Innovaatioseteli on voimassa 6 kk sen myöntämispäivämäärästä ja sitä
            voidaan käyttää ainoastaan kahden palveluntuottajan palveluihin.
            Setelin rahoitus on de minimis-ehtoista avustusta.
          </Text>
          <Text textColor="text-white">
            Seteleiden avulla voit hankkia palveluita innovaatiota tarjoavilta
            yrityksiltä, tutkimusorganisaatioilta, yliopistoilta ja
            ammattikorkeakouluilta.
          </Text>
        </Container>
      </section>

      <section id="where-to-use">
        <Container>
          <div className="grid md:grid-cols-2">
            <div className="md:mr-5">
              <Title tag="h2">Innovaatiosetelin käyttökohteita</Title>
              <Text>Esimerkkejä käyttökohteista:</Text>
              <BasicList>
                <li>tuote- ja palvelustrategian kehittämisen palvelut</li>
                <li>ketterä tuote- ja palvelukehitys, sekä kokeilut</li>
                <li>
                  mittaus ja testauspalvelut, jotka liittyvät
                  innovaatiotoimintaan
                </li>
                <li>
                  soveltuvuustutkimukset ja asiantuntijalausunnot, jotka
                  liittyvät innovaatiotoimintaan
                </li>
                <li>
                  tutkimustulosten arviointiin ja hyödyntämiseen liittyvät
                  palvelut
                </li>
              </BasicList>
            </div>
            <div className="mt-5 md:mt-0">
              <Title tag="h2">Innovaatioseteliä ei tule käyttää</Title>
              <Text>Seteliä ei tule käyttää esimerkiksi:</Text>
              <BasicList>
                <li>
                  yrityksen omiin sisäisiin palkkakustannuksiin, tarvikkeisiin,
                  vuokriin, matkoihin tai laiteostoihin
                </li>
                <li>
                  toimialojen tai toimintojen kehittämiseen, mitkä on rajattu de
                  minimis-rahoituksen ulkopuolelle
                </li>
                <li>
                  asiantuntijapalveluihin, jotka eivät liity
                  innovaatiotoimintaan
                </li>
              </BasicList>
            </div>
          </div>
        </Container>
      </section>

      <section id="small-to-medium" className="bg-gray-800">
        <Container>
          <Title tag="h2" textColor="text-white">
            Mitä tarkoitetaan pienistä keskisuuriin yrityksiin?
          </Title>
          <Text textColor="text-white">
            Pienistä keskisuuriin yrityksiin käytetään usein nimikettä
            PK-yritys. PK-yritysten kokoluokkia ovat mikroyritykset, pienet
            yritykset, sekä keskisuuret yritykset.
          </Text>
          <Text textColor="text-white">
            Suomessa käytetään kahta PK-yrityksen määritelmää. Ensimmäisessä
            määritelmä perustuu yrityksen henkilöstömäärään. Silloin
            PK-yritykseksi katsotaan yritystä, jossa on enintään 249
            työntekijää.
          </Text>
          <Text textColor="text-white">
            Toisessa katsotaan EU:n ja Tilastokeskuksen määritelmää, jossa
            yrityksessä saa olla enintään 249 työntekijää, sekä vuosiliikevaihto
            saa olla enintään 50 miljoonaa euroa tai yrityksen taseen loppusumma
            saa olla enintään 43 miljoonaa euroa. Näiden lisäksi yrityksen
            täytyy olla riippumaton.
          </Text>
        </Container>
      </section>

      <section id="applying">
        <Container>
          <Title tag="h2">Innovaatioseteliä hakiessa</Title>
          <Text>
            Sinun on mietittävä millaista asiantuntemusta ja osaamista tulet
            tarvitsemaan ideasi kehittämisessä. Sen jälkeen sinun on löydettävä
            osaava palveluntarjoaja ja kertoa hänelle tarpeesi projektin
            suhteen. Kun olette päässeet yhteisymmärrykseen on aika laatia
            työsuunnitelma, minkä jälkeen on aika tehdä hakemus Business
            Finlandin asiointipalvelussa.
          </Text>
          <Text>
            Innovaatiopalveluita hakevan yrityksen johdossa täytyy olla
            projektin vastuulinen johtaja, eli palveluntarjoaja ei voi hakea
            seteliä hakijayrityksen puolesta, mutta voimme tietenkin auttaa
            sinua sen prosessin kanssa.
          </Text>
          <Text>
            Haettavan setelin arvo on aina 6200 € (5000 € + alv.) ja sen voi
            käyttää ainoastaan kahteen nimettyyn palveluntuottajaan.
            Palveluntuottajalla ja yritykselläsi ei saa olla intressiyhteyttä.
          </Text>
          <Text>
            Jos asiantuntijapalvelun summa nousee suuremmaksi kuin 5000 € + alv.
            joutuu yrityksesi maksamaan itse erotuksen palveluntuottajalle.
          </Text>
          <Text>
            Innovaatiosetelin hakemus pyritään käsittelemään kahden viikon
            kuluessa sen saapumisesta Business Finlandiin.
          </Text>
          <div className="grid lg:grid-cols-2 mt-10">
            <div className="md:mr-5">
              <Title tag="h3">Raportointi & laskutus</Title>
              <Text>
                Viimeistään 4 kuukauden kuluttua päätöksen voimassaoloajan
                päättymisestä rahoituksen saajan tulee laatia loppuraportti
                asiointipalvelussa. Palveluntuottaja täydentää loppuraporttia
                myös asiointipalvelussa tai jollakin muulla tavalla. Rahoituksen
                saajan tulee lähettää loppuraportti Business Finlandille.
                Rahoituksen saajan on täytynyt toimittaa rahoituspäätöksen
                hyväksymisilmoitus ja loppuraportti, minkä jälkeen rahoitus
                voidaan maksaa palveluntuottajalle.
              </Text>
              <Text>
                Edellä mainitun jälkeen, palveluntuottaja lähettää laskun
                Business Finlandille. Jos palvelun arvo on pienempi kuin 5000 €,
                tulee laskun olla enintään sen palvelun hintainen.
              </Text>
              <Text>
                Sen jälkeen kun Business Finland on hyväksynyt raportin, se
                tulee maksamaan rahoituksen laskun perusteella suoraan
                palveluntuottajalle.
              </Text>
            </div>
            <div>
              <div className="mt-10 lg:mt-0">
                <Title tag="h3">Rahoituspäätös</Title>
                <Text>
                  Rahoituspäätöksen hyväksyjän on kirjauduttava
                  suomi.fi-tunnistautumisen kautta.
                </Text>
              </div>

              <div className="mt-10">
                <Title tag="h3">De Minimis -rahoitus</Title>
                <Text>
                  De minimis -rahoitus on Euroopan komission määrittelemää
                  yritystukea pieniin kehitysprojekteihin. Yritys voi saada
                  kyseistä rahoitusta enintään 200 000 € kuluvan ja kahden
                  edellisen verovuoden aikana. Business Finlandin myöntämä
                  innovaatioseteli on de minimis -rahoitusta ja se voi myöntää
                  yritykselle enintään 150 000 € de minimis -rahoitusta
                  yllämainitulle aikajaksolle.
                </Text>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="when-you-dont-get">
        <Container>
          <Title tag="h2">Milloin et voi saada innovaatioseteliä</Title>
          <BasicList>
            <li>
              jos kyseessä on toiminimi, julkinen tai voittoa tavoittelematon
              organisaatio, suuryritys, yhdistys, säätiö tai ahvenanmaalainen
              yritys
            </li>
            <li>jos yritykselläsi on verovelkaa</li>
            <li>jos yrityksesi ei kuulu ennakkoperintärekisteriin</li>
            <li>jos yrityksesi asiakastiedon rating Alfa on huonompi kuin A</li>
            <li>
              jos hankittava ulkopuolinen palvelu tai osaaminen ei sisällöltään
              ole osaamiseen nähden uutta
            </li>
            <li>
              jos yritykselläsi ei ole aikomusta kansainvälistymiseen tai siihen
              liittyviä suunnitelmia
            </li>
            <li>
              jos yrityksesi on toimialalla mihin ei voida myöntää de
              minimis-muotoista rahoitusta
            </li>
            <li>
              jos yrityksellesi on jo myönnetty Tekesin tai Business Finlandin
              innovaatioseteli
            </li>
            <li>jos kyse on tiedon hankkimisesta uusista vientimarkkinoista</li>
            <li>
              jos yrityksen, johon yrityksesi kuulu, de minimis-kiintiö ylittyy
            </li>
            <li>
              jos projekti liittyy yrityksen tai sen tuotteen tai palvelun
              liittyvän rahoituksen hankintaan, selvittämiseen, sijoittajien ja
              rahoittajien sekä osakkaiden kartoittamiseen
            </li>
            <li>
              jos yrityksellesi on myönnetty Tekesin tai Business Finlandin
              rahoitusta kuluvana tai edellisenä vuonna
            </li>
            <li>
              jos yritykselläsi tai samaan konserniin kuuluvalla yrityksellä on
              käynnissä Tekesin tai Business Finlandian rahoittama projekti
            </li>
            <li>
              jos yrityksesi tai samaan konserniin kuuluva yritys on jo jättänyt
              muun hakemuksen Tekesiin tai Business Finlandiin
            </li>
          </BasicList>
          <div className="mt-10">
            <Text>
              Lisätietoja innovaatioseteleistä löydät{" "}
              <Link to="https://www.businessfinland.fi/suomalaisille-asiakkaille/palvelut/rahoitus/tutkimus-ja-kehitysrahoitus/innovaatioseteli">
                täältä
              </Link>
              .
            </Text>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
